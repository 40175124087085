import { createApp } from "vue";
import ViewUiPlus from "view-ui-plus";
//引入仓库
import pinia from "./store";
import App from "./App.vue";
import router from "./router";
import "view-ui-plus/dist/styles/viewuiplus.css";
import VueQrcode from "vue-qrcode";
// 拖拽
import "es-drager/lib/style.css";
import Drager from "es-drager";
// 全局样式
import "./styles/index.less";
import "./styles/index_new.less";
import scrollLoadMoreDirective from "./scrollLoadMoreDirective";
const app = createApp(App);
// 全局注册自定义指令
app.directive("scroll-load-more", scrollLoadMoreDirective);
app.component("es-drager", Drager);
app.component("vue-qrcode", VueQrcode);
//安装仓库
app.use(pinia);
app.use(router);
app.use(ViewUiPlus);
app.mount("#app");
