import type { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/merchant_code/:merchant_code",
    redirect: "/accountOverall/index",
  },
  {
    path: "/",
    component: () => import("@/views/Home/index.vue"),
    redirect: "/accountOverall/index",
    meta: {
      title: "首页",
      menuName: "1-1",
    },
    children: [
      {
        path: "/accountOverall/index",
        component: () => import("@/views/accountOverall/index.vue"),
        meta: {
          title: "数据概况",
          menuName: "1-1",
        },
      },
      {
        path: "/accountOverall/Recharge-Package",
        component: () => import("@/views/accountOverall/Recharge-Package.vue"),
        meta: {
          title: "充值套餐",
          menuName: "1-1",
        },
      },
      {
        path: "/accountOverall/Transfer-records",
        component: () => import("@/views/accountOverall/Transfer-records.vue"),
        meta: {
          title: "转账记录",
          menuName: "1-1",
        },
      },
      {
        path: "/accountOverall/space_add",
        component: () => import("@/views/accountOverall/space_add.vue"),
        meta: {
          title: "空间扩容",
          menuName: "1-1",
        },
      },
      {
        path: "/accountOverall/space_log",
        component: () => import("@/views/accountOverall/space_log.vue"),
        meta: {
          title: "扩容记录",
          menuName: "1-1",
        },
      },
      {
        path: "/accountOverall/Editing-balance-records",
        component: () => import("@/views/accountOverall/Editing-balance-records.vue"),
        meta: {
          title: "剪辑余额记录",
          menuName: "1-1",
        },
      },
      {
        path: "/accountManage/index",
        component: () => import("@/views/accountManage/index.vue"),
        meta: {
          title: "账户管理",
          menuName: "1-4-1",
          menuHome: "1-4",
        },
      },
      {
        path: "/accountManage/accountDetail",
        component: () => import("@/views/accountManage/accountDetail.vue"),
        meta: {
          title: "账户管理详情",
          menuName: "1-4-1",
          menuHome: "1-4",
        },
      },
      {
        path: "/accountManage/AccountGrouping",
        component: () => import("@/views/accountManage/AccountGrouping.vue"),
        meta: {
          title: "账号分组",
          menuName: "1-4-2",
          menuHome: "1-4",
        },
      },
      {
        path: "/localMaterial/index",
        component: () => import("@/views/localMaterial/index.vue"),
        meta: {
          title: "素材管理",
          menuName: "1-2",
        },
      },

      {
        path: "/aiSuper/index",
        component: () => import("@/views/aiSuper/index.vue"),
        meta: {
          title: "AI超级文案编导",
          menuName: "1-11",
        },
      },
      {
        path: "/aiSuper/LensDetail/:id",
        name: "LensDetail",
        component: () => import("@/views/aiSuper/LensDetail.vue"),
        meta: {
          title: "镜头文案生成",
          menuName: "1-10-1",
          menuHome: "1-10",
        },
      },
      {
        path: "/aiSuper/LensImitation/:id",
        name: "LensImitation",
        component: () => import("@/views/aiSuper/LensImitation.vue"),
        meta: {
          title: "镜头文案仿写",
          menuName: "1-10-2",
          menuHome: "1-10",
        },
      },
      {
        path: "/seniorEditor/senior-index/:id",
        name: "seniorIndex",
        component: () => import("@/views/seniorEditor/senior-index.vue"),
        meta: {
          title: "镜头剪辑",
          menuName: "1-3-1",
          menuHome: "1-3",
        },
      },
      {
        path: "/seniorEditor/senior-edit/:id/:is_pre",
        name: "seniorEdit",
        component: () => import("@/views/seniorEditor/senior-edit.vue"),
        meta: {
          title: "镜头剪辑",
          menuName: "1-3-3",
          menuHome: "1-3",
        },
      },
      {
        path: "/clip-task/index",
        component: () => import("@/views/clip-task/index.vue"),
        meta: {
          title: "剪辑任务",
          menuName: "1-3-4",
          menuHome: "1-3",
        },
      },
      {
        path: "/combination-list/:id",
        name: "combination",
        component: () => import("@/views/clip-task/combination-list.vue"),
      },

      {
        path: "/videoTem/index",
        component: () => import("@/views/videoTem/index.vue"),
        meta: {
          title: "视频模板",
          menuName: "1-3-3",
          menuHome: "1-3",
        },
      },
      {
        path: "/videoLaunch/index",
        component: () => import("@/views/videoLaunch/index.vue"),
        meta: {
          title: "智能发布",
          menuName: "1-6-1",
          menuHome: "1-6",
        },
      },
      {
        path: "/videoLaunch/addPlan",
        component: () => import("@/views/videoLaunch/addPlan.vue"),
        meta: {
          title: "智能发布",
          menuName: "1-6-1",
          menuHome: "1-6",
        },
      },
      // {
      //     path: "/individually-video-publish/index",
      //     component: () => import("@/views/individually-video-publish/index"),
      //     meta: {
      //         title: "人工发布",
      //         menuName: "1-6-2",
      //         menuHome: "1-6",
      //     },
      // },
      {
        path: "/individually-task-list",
        component: () => import("@/views/individually-video-publish/individually-task-list.vue"),
      },
      {
        path: "/poi/index",
        component: () => import("@/views/poi/index.vue"),
      },
      {
        path: "/videoManage/index",
        component: () => import("@/views/videoManage/index.vue"),
        meta: {
          title: "视频列表",
          menuName: "1-5-1",
          menuHome: "1-5",
        },
      },
      {
        path: "/commentsManage/index",
        component: () => import("@/views/commentsManage/index.vue"),
      },
      {
        path: "/privateMessage/index",
        component: () => import("@/views/privateMessage/index.vue"),
      },
      {
        path: "/clueManage/index",
        component: () => import("@/views/clueManage/index.vue"),
      },
      {
        path: "/rule/index",
        component: () => import("@/views/rule/index.vue"),
      },
      {
        path: "/tool/index",
        component: () => import("@/views/tool/index.vue"),
      },
      {
        path: "/tool/extractVideo",
        component: () => import("@/views/tool/extractVideo.vue"),
      },
      {
        path: "/tool/extractAudio",
        component: () => import("@/views/tool/extractAudio.vue"),
      },
      {
        path: "/tool/reverseVideo",
        component: () => import("@/views/tool/reverseVideo.vue"),
      },
      {
        path: "/tool/videoCropping",
        component: () => import("@/views/tool/videoCropping.vue"),
      },
      {
        path: "/tool/revolveVideo",
        component: () => import("@/views/tool/revolveVideo.vue"),
      },
      {
        path: "/tool/textToVoice2/:id",
        name: "textToVoice2",
        component: () => import("@/views/tool/textToVoice2.vue"),
        meta: {
          title: "配音详情",
          menuName: "1-2",
          menuHome: "1-2",
        },
      },
      {
        path: "/appManage/index",
        component: () => import("@/views/appManage/index.vue"),
      },
      // 分享海报
      {
        path: "/Share/share_list",
        component: () => import("@/views/Share/share_list.vue"),
        meta: {
          name: "我的分享海报",
          menuName: "1-6-3",
          menuHome: "1-6",
        },
      },
      {
        path: "/Share/index",
        component: () => import("@/views/Share/index.vue"),
        meta: {
          name: "海报市场",
          menuName: "1-6-3",
          menuHome: "1-6",
        },
      },

      // 记录管理
      {
        path: "/MoneyLog/clip_num_log",
        component: () => import("@/views/MoneyLog/clip_num_log.vue"),
        meta: {
          name: "账变记录",
          menuName: "1-7-1",
          menuHome: "1-7",
        },
      },
      {
        path: "/MoneyLog/recharge_log",
        component: () => import("@/views/MoneyLog/recharge_log.vue"),
        meta: {
          name: "充值记录",
          menuName: "1-7-2",
          menuHome: "1-7",
        },
      },
      {
        path: "/MoneyLog/use_log",
        component: () => import("@/views/MoneyLog/use_log.vue"),
        meta: {
          name: "分发记录",
          menuName: "1-7-3",
          menuHome: "1-7",
        },
      },
      {
        path: "/MoneyLog/commission_log",
        component: () => import("@/views/MoneyLog/commission_log.vue"),
        meta: {
          name: "佣金记录",
          menuName: "1-7-4",
          menuHome: "1-7",
        },
      },
      // 店铺
      {
        path: "/Shop-Management/index",
        component: () => import("@/views/Shop-Management/index.vue"),
        meta: {
          name: "店铺管理",
          menuName: "1-8-1",
          menuHome: "1-8",
        },
      },
      // ================话题管理=====================
      {
        path: "/Topic-Management/Topic-Grouping",
        component: () => import("@/views/Topic-Management/Topic-Grouping.vue"),
        meta: {
          name: "话题分组",
          menuName: "1-9-1",
          menuHome: "1-9",
        },
      },
      {
        path: "/Topic-Management/Topic-List",
        component: () => import("@/views/Topic-Management/Topic-List.vue"),
        meta: {
          name: "话题列表",
          menuName: "1-9-2",
          menuHome: "1-9",
        },
      },
    ],
  },
  {
    path: "/dataScreen/index",
    component: () => import("@/views/dataScreen/dataScreen.vue"),
    meta: {
      title: "数据大屏",
      menuName: "1-9",
    },
  },
  // 登录页
  {
    path: "/login/index",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/localMaterial/phone_upload",
    component: () => import("@/views/localMaterial/phone_upload.vue"),
  },
  // 海报分享模板添加
  {
    path: "/Share/poster_add",
    component: () => import("@/views/Share/poster_add.vue"),
  },
  // 海报分享模板编辑
  {
    path: "/Share/poster_edit/:id/:is_sys",
    name: "posterEdit",
    component: () => import("@/views/Share/poster_edit.vue"),
  },
  // 海报市场添加
  {
    path: "/Share/index_posterAdd",
    component: () => import("@/views/Share/index_posterAdd.vue"),
  },
  // 海报市场编辑
  {
    path: "/Share/index_posterEdit/:id/:is_sys",
    name: "index_posterEdit",
    component: () => import("@/views/Share/index_posterEdit.vue"),
  },
];

export default routes;
