// scrollLoadMoreDirective.ts
import { DirectiveBinding } from "vue";

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding<() => void>) {
    const SELECTWRAP_DOM = el.querySelector(".ivu-select-dropdown");

    if (!SELECTWRAP_DOM) {
      console.warn("找不到.ivu-select-dropdown元素，请确保Select组件已正确渲染");
      return;
    }

    SELECTWRAP_DOM.addEventListener("scroll", () => {
      const condition = SELECTWRAP_DOM.scrollHeight - SELECTWRAP_DOM.scrollTop <= SELECTWRAP_DOM.clientHeight;
      if (condition) {
        binding.value?.(); // 调用传递给指令的函数来加载更多数据
      }
    });
  },
  unmounted(el: HTMLElement) {
    // 在指令解绑时，移除事件监听器
    const SELECTWRAP_DOM = el.querySelector(".ivu-select-dropdown");
    if (SELECTWRAP_DOM) {
      SELECTWRAP_DOM.removeEventListener("scroll", () => {});
    }
  },
};
